import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BackLink from 'components/BackLink'
import Seo from '../components/seo'
import Landing from 'components/Landing'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../theme'

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "about" } }) {
      slug {
        current
      }
      intro
      title
      hero {
        backgroundImage {
          asset {
            url
            gatsbyImageData(fit: FILLMAX)
          }
        }
        caption
        title
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const page = data.sanityPage
  console.log('About page:', page)

  return (
    <ThemeProvider theme={theme}>
      <Landing
        title={<h1 style={{ paddingLeft: 20 }}>About</h1>}
        height={80}
        backgroundImage={page.hero.backgroundImage?.asset.gatsbyImageData}
      />
      <Layout>
        <Seo title="About" />
        {/* <h1>About me:</h1> */}
        <div style={{ marginTop: 40, marginBottom: 80, padding: '0 8px' }}>
          <p>
            I believe in bringing the power of computing to as many people as
            possible by making accessible and intuitive interfaces. I work
            primarily with Javascript in various forms, from front-end
            development in React, to back-end systems with Express and NodeJS.
            My most recent work has involved localization, natural language
            validation and text extraction. I love working with people from
            diverse backgrounds who are passionate about what they do.
          </p>
          <p>
            I feel lucky to have grown up in two different cultures and
            countries. I lived in the Bay Area until the age of twelve, then
            moved to Stockholm, Sweden. Although it was certainly a difficult
            transition, it taught me a lot about being observant of other
            people's perspectives and being able to adapt to new situations.
            After high school, I spent some time playing music in various bands
            in and around Sweden. I also began working with graphic design and
            screen printing under the name Phonofidelic. In 2010 I began
            studying art and printmaking at the College of Printmaking Arts in
            Stockholm. During that time, the work I was doing began to take on
            less of a visual significance as I became more concerned with
            meaning production and what kind of things are being said through
            the process of creating art. These questions led me to begin
            studying Philosophy and Linguistics at Stockholm University in 2012.
            I was introduced to the concepts of semantics and formal logic which
            put into context many of the issues I was trying to deal with in art
            school.
          </p>
          <p>
            One of the things that drew me to programming was that it felt like
            the practical application of the concepts I was studying in
            philosophy. I could see abstract ideas taking the form of objects
            and classes on the screen in front of me. I found it exciting to see
            that I could turn ideas into a program that would run on a computer
            and that excitement led me deeper into web development. Since first
            starting my journey into web development in 2013, I have been able
            to use the skills I acquired to solve challenging problems and build
            tools and applications that I love to use on a daily basis. What I
            love most about this space is that I continue to learn and grow each
            day as I face new challenges.
          </p>
        </div>
        <BackLink />
      </Layout>
    </ThemeProvider>
  )
}

export default AboutPage
